import { combineReducers } from '@reduxjs/toolkit';
import domainsReducer, { DomainsState } from './domainsSlice';
import partnerReducer, { PartnersState } from './partnersSlice';
import scriptsReducer, { ScriptsState } from './scriptsSlice';
import currenciesReducer, { CurrenciesState } from './currenciesSlice';
import industriesReducer, { IndustriesState } from './industriesSlice';
import automationsReducer, { AutomationsState } from './automationsSlice';

const reducer = combineReducers({
    partnersState: partnerReducer, 
    domainsState: domainsReducer,
    scriptsState: scriptsReducer,
    currenciesState: currenciesReducer,
    industriesState: industriesReducer,
    automationsState: automationsReducer,
});

export type DataState = {
    partnersState: PartnersState;   
    domainsState: DomainsState;     
    industriesState: IndustriesState;     
    scriptsState: ScriptsState;     
    currenciesState: CurrenciesState;
    automationsState: AutomationsState;
};

export * as partnerSlice from './partnersSlice';
export * as domainSlice from './domainsSlice';
export * as industriesSlice from './industriesSlice';
export * as scriptSlice from './scriptsSlice';
export * as currencySlice from './currenciesSlice';
export * as automationSlice from './automationsSlice';

export default reducer;
