import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_BASE_NAME } from './constants';
import type { Automation } from '@/@types/data';

export type AutomationsState = {
    automations: Automation[];
    isLoading: boolean;
    error: string | null;
}

const initialState: AutomationsState = {
    automations: [],
    isLoading: false,
    error: null,
};

const automationsSlice = createSlice({
    name: `${SLICE_BASE_NAME}/automation`,
    initialState,
    reducers: {
        setAutomations: (state, action: PayloadAction<Automation[]>) => {
            state.automations = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const { setAutomations, setLoading, setError } = automationsSlice.actions;

export default automationsSlice.reducer;
