import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_BASE_NAME } from './constants';
import type { Script } from '@/@types/data';

export type ScriptsState = {
    scripts: Script[];
    isLoading: boolean;
    error: string | null;
}

const initialState: ScriptsState = {
    scripts: [],
    isLoading: false,
    error: null,
};

const scriptsSlice = createSlice({
    name: `${SLICE_BASE_NAME}/scripts`,
    initialState,
    reducers: {
        setScripts: (state, action: PayloadAction<Script[]>) => {
            state.scripts = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const { setScripts , setLoading, setError } = scriptsSlice.actions;

export default scriptsSlice.reducer;
