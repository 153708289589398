export const APP_NAME = 'Kidon'
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'redirectUrl'

export enum Automation_Status {
    Initial = 'Initial',
    ManualImport = 'Manual Import',
    Inactive = 'Inactive',
    Active = 'Active',
    OnlyImport = 'Only Import',
}

export const statusOptions = [
    Automation_Status.Initial,
    Automation_Status.ManualImport,
    Automation_Status.Inactive,
    Automation_Status.Active,
    Automation_Status.OnlyImport,
].map((str) => ({ id: str, title: str }))




export enum Domain_Status {
    Active = 'Active',
    Inactive = 'Inactive'
}

export const domainStatusOptions = [
    Domain_Status.Active,
    Domain_Status.Inactive
].map((str) => ({ id: str, title: str }))



export const dateFormatOptions = [
    { id: 'Auto', title: 'Auto' },
    { id: 'Unix', title: 'Unix' },
    { id: "YYYY-DD-MM", title: "YYYY-DD-MM" },
    { id: "YYYY-MM-DD", title: "YYYY-MM-DD" },
    { id: "DD-MM-YYYY", title: "DD-MM-YYYY" },
    { id: "MM-DD-YYYY", title: "MM-DD-YYYY" },
    { id: "D-M-YYYY", title: "D-M-YYYY" },
    { id: "x", title: "unix" }
];
